import React from 'react';

const Description = ({tags}) => {
  const descriptionText = tags.map((tag, index) => {
    if(index === 0) {
      return <strong key={index}>
        {tag}
      </strong>
    }
    else {
      return <span key={index}> - {tag}</span>
    };
  });

  return (
    <div className='description'>
      <h1>{descriptionText}</h1>
    </div>
  )
}

export default Description
