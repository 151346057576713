import React from 'react';
import { remove, includes } from 'lodash';

const TagListWithCounts = ({tagsWithCount, tagComponent, reservedTags}) => {
  const Tag = tagComponent;
  // filter reservedTags from display
  if(reservedTags) {
    tagsWithCount = tagsWithCount.filter((tagWithCount) => {
      return reservedTags.indexOf(tagWithCount[0]) === -1
    });

    // partial
    reservedTags.map((reservedTag) => {
      tagsWithCount = tagsWithCount.filter((tagWithCount) => {
        return tagWithCount[0].indexOf(reservedTag) === -1
      })
    })
  }

  if(tagsWithCount.length === 0 || !Tag) { return null }

  // HACK
  // push these to front
  const terms = ['term1', 'term-1', 'term 1', 'term01', 'term-01', 'term 01',
  'term2', 'term-2', 'term 2', 'term02', 'term-02', 'term 02',
  'term3', 'term-3', 'term 3', 'term03', 'term-03', 'term 03'];

  let termTags = remove(tagsWithCount, (tag) => {
    return includes(terms, tag[0])
  });

  tagsWithCount.unshift(...termTags);

  return (
    <div className='tags'>
      <i className='fa fa-tag'/>
      {
        tagsWithCount.map((tag, index, tags) => {
          const delim = tags.length -1 === index ? ' ' : ', '
          return <Tag key={index} tag={tag} delim={delim} />
        })
      }
    </div>
  )
}

export default TagListWithCounts;
