import React, { Component, Fragment } from 'react';
import { renderToStaticMarkup } from 'react-dom/server'

import Modal from 'react-responsive-modal';
import DropzoneComponent from 'react-dropzone-component';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import { ApiUrl } from '../../../config';
import TagSelector from '../components/TagSelector';
import PreviewImage from '../components/PreviewImage';


const SortablePreview = SortableElement(PreviewImage);
const SortablePreviewWrapper = SortableContainer(({files, dz, onFileRemove, coverId}) => {
  return (
    <div className='preview-wrapper'>
      {
        files.map((file, index) => {
          return (
            <Fragment key={index}>
              <SortablePreview
                key={`preview-${file.upload.uuid}`}
                index={index}
                uuid={file.upload.uuid}
                dz={dz}
                file={file}
                onFileRemove={onFileRemove}
              />
            </Fragment>
          )
        })
      }
    </div>
  )
});

class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      files: [],
      coverId: 0,
      attributions: {},
    }

    this.componentConfig = {
      iconFiletypes: ['.jpg', '.jpeg', '.png', '.gif'],
      showFiletypeIcon: true,
      postUrl: ApiUrl + '/images',
    };

    this.djsConfig = {
      addRemoveLinks: false,
      autoProcessQueue: false,
      paramName: "image[file]",
      parallelUploads: 1,
      dictDefaultMessage: "Drop files here or click to select files for upload",
      previewTemplate: renderToStaticMarkup(
        // remove file.previewTemplate to allow custom PreviewImage
        <div></div>
      )
    };
  }

  handleFileAdded(file) {
    this.setState({
      files: [
        file,
        ...this.state.files,
      ]
    })
  }

  handleFilesSending(file, xhr, formData) {
    let tags = this.state.tags;
    const isCoverImage = file.cover === "on";
    const attribution = file.attribution;
    tags = isCoverImage ? [...tags, 'coverimage'] : tags
    tags = attribution ? [...tags, `artist: ${attribution}`] : tags

    formData.append('tags', JSON.stringify(tags));
  }

  handleTagSubmit(tags) {
    const tagValues = tags.map(tag => tag.value);
    this.setState({ tags: tagValues });
  }

  handleSubmit(event) {
    event.preventDefault();

    // ensure dropzone state matches DOM state.
    // We reverse the queue to process bottom to top (to get latest image desc)
    // but also reverse the DOM so the initial order remains the same
    this.dropzone.removeAllFiles();
    const reversedFiles = [...this.state.files].reverse();
    this.dropzone.handleFiles(reversedFiles);

    //prevent duplicate DOM objects by refreshing the DOM
    this.setState({ files: this.state.files });

    this.dropzone.options.autoProcessQueue = true;
    this.dropzone.processQueue();
  }

  // HACK file progress is not working due to preflight OPTIONS request triggering 'uploadprogress'
  // fake progress for now
  handleProgress(file, progress, bytesSent) {
    const uuid = file.upload.uuid;
    const progressElement = this.dropzone.element.querySelector(`.dz-progress[data-uuid='${uuid}'] .dz-upload`);
    const actionsElement = this.dropzone.element.querySelectorAll('.dz-actions').forEach((el) => {
      el.classList.add('dz-processing');
    });
    progressElement.classList.add('dz-processing');
  }

  handleSuccess(file) {
    const uuid = file.upload.uuid;
    const previewElement = this.dropzone.element.querySelector(`.dz-file-preview[data-uuid='${uuid}']`);
    const progressElement = this.dropzone.element.querySelector(`.dz-progress[data-uuid='${uuid}'] .dz-upload`);

    progressElement.classList.add('dz-success');
    previewElement.classList.add('dz-success');
  }

  handleComplete() {
    this.dropzone.processQueue();
  }

  handlequeuecomplete() {
    // process any items added during queue processing
    this.dropzone.processQueue();
    this.dropzone.options.autoProcessQueue = true;
  }

  initdz(dz) {
    this.dropzone = dz;
  }

  onSortEnd({ oldIndex, newIndex }) {
    const files = arrayMove(this.state.files, oldIndex, newIndex);
    this.setState({ files });
  };

  handleCloseModal() {
    this.setState({ files: [] });
    this.props.onCloseImageUploader();
  }


  handleFileRemove(removedFile) {
    const updatedFiles = this.state.files.filter((file) => file !== removedFile );
    this.setState({
      files: updatedFiles
    })
  }


  render() {
    const eventHandlers = {
      init: (dz) => this.initdz(dz),
      addedfile: this.handleFileAdded.bind(this),
      sending: (file, xhr, formData) => this.handleFilesSending(file, xhr, formData),
      uploadprogress: (file, progress, bytesSent) => this.handleProgress(file, progress, bytesSent),
      complete: () => this.handleComplete(),
      queuecomplete: () => this.handlequeuecomplete(),
      success: (file) => this.handleSuccess(file),
    };

    let fileCount = this.state.files.length
    const uploaderState = !fileCount || !this.state.tags.length ? { 'disabled': 'disabled' } : null;
    let uploadButtonText = `Upload ${fileCount} file`
    uploadButtonText += !fileCount === 1 ? '' : 's'

    return (
      <div>
        <Modal open={this.props.isOpen} onClose={()=>this.handleCloseModal()} little>
          <h3>Drag items to re-order</h3>
          <DropzoneComponent config={this.componentConfig}
                             eventHandlers={eventHandlers}
                             djsConfig={this.djsConfig}>
            <SortablePreviewWrapper
              coverId={this.state.coverId}
              onFileRemove={(file)=>this.handleFileRemove(file)}
              files={this.state.files}
              dz={this.dropzone}
              axis={'y'}
              lockAxis={'y'}
              helperClass='sorting-item'
              lockToContainerEdges={true}
              onSortEnd={(oldIndex, newIndex) => this.onSortEnd(oldIndex, newIndex)} />
          </DropzoneComponent>
          <h3>Add tags to this collection</h3>
          <TagSelector
            onValuesChange={(tags)=>this.handleTagSubmit(tags)}
            placeholder="Enter tag(s)"
            delimiters={[188, 9]}
            selectedImageTags={this.state.tags}
            existingImageTags={this.props.existingImageTags}
            reservedTags={this.props.reservedTags}
          />
          <div className='uploader-actions'>
            <input {...uploaderState} type='submit' value={uploadButtonText} onClick={(event)=>this.handleSubmit(event)}/>
          </div>
        </Modal>
      </div>
    )
  }
}

export default ImageUploader;
