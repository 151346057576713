import React, { Fragment } from 'react';
import { replace, startsWith, find, remove, includes } from 'lodash';

const Attribution = ({tags}) => {
  let attribution = remove(tags, (tag) => startsWith(tag, 'artist:'))
  attribution = replace(attribution, 'artist: ', '');

  // HACK
  // push these to front
  const terms = ['term1', 'term-1', 'term 1', 'term01', 'term-01', 'term 01',
  'term2', 'term-2', 'term 2', 'term02', 'term-02', 'term 02',
  'term3', 'term-3', 'term 3', 'term03', 'term-03', 'term 03'];

  let termTags = remove(tags, (tag) => {
    return includes(terms, tag)
  });

  tags.unshift(...termTags);

  const descriptionText = tags.slice(0, 3).map((tag, index, arr) => {
    if(index + 1 !== arr.length) {
      return (
        <Fragment key={index}>
          <span>{tag}</span>
          <span> | </span>
        </Fragment>
      )
    }
    else {
      return <span key={index}>{tag}</span>
    };
  });

  return (
    <div className='attribution-bar'>
      <div className='attribution'>
        {descriptionText}
        <span> | {attribution}</span>
      </div>
    </div>
  );
}

export default Attribution;
