import superagent from 'superagent';
import superagentAbsolute from 'superagent-absolute';
import { ApiUrl } from '../config';

const agent = superagent.agent();
const request = superagentAbsolute(agent)(ApiUrl);
// props.path must start with /, otherwise they will be treated as relative.

export const get = ({
                      path = '/images', query, success, failure,
                    }) => {
  request.get(path)
    .set('Content-Type', 'application/json')
    .query(query)
    .then(success)
    .catch(failure);
};

export const patch = ({
                        path = '/images', data, success, failure,
                      }) => {
  request.patch(path)
    .set('Content-Type', 'application/json')
    .send(data)
    .then(success)
    .catch(failure);
};

export const del = ({ path = '/images', success, failure }) => {
  request.delete(path)
    .set('Content-Type', 'application/json')
    .then(success)
    .catch(failure);
};
