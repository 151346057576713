import React from 'react';

const TagWithCount = ({tag, delim}) => {
  return (
    <span className='tag'>
      {`${tag[0]} [${tag[1]}]${delim}`}
    </span>
  )
}

export default TagWithCount;
