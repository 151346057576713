import React, { Component } from 'react';
import Gallery from 'react-photo-gallery';
import Measure from 'react-measure';

class GalleryContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: -1,
    };
  };

  render() {
    const width = this.state.width;
    const columns = ((width) => {
      switch(true) {
        case (width >= 1500) : return 5;
        case (width >= 1140) : return 4;
        default: return 3;
      }
    })(width);

    return (
      <div className='gallery'>
        <Measure bounds onResize={(contentRect) => this.setState({ width: contentRect.bounds.width })}>
          {
            ({measureRef}) => {
              if (width < 1){
                return <div ref={measureRef}></div>;
              }

              return (
                <div ref={measureRef}>
                  <Gallery
                    photos={this.props.images}
                    onClick={(event, obj) => this.props.onSelectImage(event, obj)}
                    ImageComponent={this.props.imageComponent}
                    columns={columns}
                  />
                </div>
              )
            }
          }
        </Measure>
      </div>
    )
  }
}

export default GalleryContainer;
