import React, { PureComponent } from 'react';
import { Group } from 'react-filters';

import { sortBy, pullAll, uniq, flattenDeep, find, includes } from 'lodash';

import Gallery from '../common/components/Gallery';
import FilterBar from './components/FilterBar';
import ClickableGallery from './components/ClickableGallery';
import ReactPhotoGalleryAdapter from '../common/adapters/v1/photoGallery';

import * as Api from '../utils/api';
import { sanitizeQuery } from '../utils/utilities';

class MediaGallery extends PureComponent {
  state = {
    query: '',
    reservedTags: ['coverimage', 'featured', 'hero'],
    images: [],
    imageTags: [],
    sortOption: 0,
    filtered: false,
    // imageTagsWithCount: [],
  }

  onSuccess(response) {
    const images = ReactPhotoGalleryAdapter(response)
    let imageTags = images.map((image) => {
      return image.object.attributes.tags
    });

    // optional tags with counts, not implemented
    // let imageTagsWithCount = images.map((image) => {
    // return image.object.attributes.tags_with_count
    // });

    // const flatTagsWithCount = [].concat.apply([], imageTagsWithCount)
    // const sortedUniqTags = sortBy(uniqWith(flatTagsWithCount, _.isEqual));
    // imageTagsWithCount = filter(sortedUniqTags, (tag) => !includes(this.state.reservedTags, tag[0]));
    // const sortedUniqTags = sortBy(uniq(flattenDeep(imageTagsWithCount)));

    const sortedUniqTags = sortBy(uniq(flattenDeep(imageTags)));
    imageTags = pullAll(sortedUniqTags, this.state.reservedTags);

    this.setState({
      images,
      imageTags,
      // imageTagsWithCount,
    });
  }

  onFailure(error, response){
    console.log(response, error);
  }

  requestImages(query = '') {
    const sanitizedQuery = {
      ...query,
      tags: sanitizeQuery(query.tags)
    };
    this.setState({ query })

    Api.get({
      query: sanitizedQuery,
      success: (response) => this.onSuccess(response),
      failure: (error) => this.onFailure(error)
    })
  };

  componentDidMount() {
    this.requestImages(this.props.query)
  }

  handleFilterChange(query) {
    this.setState({ filtered: query.length })
    this.requestImages({
      ...this.props.query,
      tags: `${this.props.query.tags}, ${query}`,
    });
  };

  handleSortChange({ oldValue, value, selectedIds}) {
    let selectedOptions = find(value, (val) => selectedIds === val.id);
    let order = selectedOptions.sortBy;

    this.setState({
      sortOption: !this.state.sortOption
    })

    this.requestImages({
      ...this.state.query,
      order
    });
  }


  render() {
    const filterClass = this.state.filtered ? 'filtered': ''

    return (
      <div className='media-gallery'>
        <div className='toolbar'>

          <div className='filter-group'>
            <div className={`accordion ${filterClass}`}>
              <div className="card">
                <div className="card-header collapsed" id="filterHeading" aria-controls="filterCollapse" aria-expanded="false" data-target="#filterCollapse" data-toggle="collapse">
                  <a>Filter options:</a>
                </div>
                <div className="collapse" id="filterCollapse" aria-labelledby="filterHeading" data-target="#accordion">
                  <div className="card-body filter-options">
                    <FilterBar
                      tagValues={this.state.imageTags}
                      onValueChange={(query)=>this.handleFilterChange(query)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='sort-group'>
            <label htmlFor='sort-by'>Sort by:</label>
            <Group
              name='group-tag'
              type={'radio'}
              value={[
                { id: 0, label: 'Newest', value: !this.state.sortOption, sortBy: 'desc' },
                { id: 1, label: 'Oldest', value: !!this.state.sortOption, sortBy: 'asc' }
              ]}
              mode={'tag'}
              onChange={(radioGroup)=>this.handleSortChange(radioGroup)}
            />
          </div>
        </div>
        <div className='main'>
          <Gallery
            images={this.state.images}
            query={this.props.query}
            imageComponent={ClickableGallery}
          />
        </div>
      </div>
    );
  }
}

export default MediaGallery;
