
const ReactPhotoGalleryAdapter = (response) => {
  if (!response || !response.body.meta.total > 0) { return []; }

  const results = response.body.data;
  return results.map(result => ({
    id: result.id,
    src: result.attributes.file_url,
    thumb: result.attributes.file.small.url,
    medium: result.attributes.file.medium.url,
    large: result.attributes.file.large.url,
    height: result.attributes.height,
    width: result.attributes.width,
    tags: result.attributes.tags,
    tags_with_count: result.attributes.tags_with_count,
    object: result,
  }));
};

export default ReactPhotoGalleryAdapter;
