import React from 'react';
import PropTypes from 'prop-types';
import ImageLoader from 'react-loading-image';

import TagListWithCounts from '../../common/components/TagListWithCounts';
import { sanitizeQuery } from '../../utils/utilities';

const Slide = ({image, src, index, className, tagComponent, reservedTags, onClick}) => {
  let href;
  let imageTags = image.tags

  if(reservedTags) {
    imageTags = imageTags.filter((tag) => {
      return reservedTags.indexOf(tag) === -1
    });

    // partial
    reservedTags.map((reservedTag) => {
      imageTags = imageTags.filter((imageTag) => {
        return imageTag.indexOf(reservedTag) === -1
      })
    })
  };

  if(imageTags.length) {
    const sanitizedQuery = sanitizeQuery(imageTags.join());
    href = `/galleries/gallery?tags=${sanitizedQuery}&uid=${image.id}`;
  };

  const onClickHandler = (event) => onClick(event, { image, index });
  const anchor = onClick ? { onClick: onClickHandler, href: 'javascript:void(0);' }  : { href }


  return (
    <div className='slide-image-wrapper'>
      <a {...anchor} >
        <ImageLoader
          src={src}
          image={() => <img src={src} className={className}/>}
          loading={()=><div className='loading'><i className='fa fa-spin fa-spinner fa-2x'/></div> }
          error={()=><div className='loading'><i className='fa fa-spin fa-spinner fa-2x'/></div> }
        />
      </a>

      <div className='metadata'>
        <TagListWithCounts
          tagsWithCount={image.tags_with_count}
          tagComponent={tagComponent}
          reservedTags={reservedTags}
        />
      </div>
    </div>
  );
}

export default Slide;
