import React from 'react';
import PropTypes from 'prop-types';
import ImageLoader from 'react-loading-image'

import { sanitizeQuery } from '../../utils/utilities';
import Attribution from './Attribution';

const Hero = ({image, src, index, className, tagComponent, reservedTags, onClick}) => {
  let href;
  let imageTags = image.tags

  if(reservedTags) {
    imageTags = imageTags.filter((tag) => {
      return reservedTags.indexOf(tag) === -1
    });
  };

  // uncomment if click handler required
  // if(imageTags.length) {
  //   const sanitizedQuery = sanitizeQuery(imageTags.join());
  //   href = `/galleries/gallery?tags=${sanitizedQuery}&uid=${image.id}`;
  // };

  // const onClickHandler = (event) => onClick(event, { image, index });
  // this.anchor = onClick ? { onClick: onClickHandler, href: 'javascript:void(0);' }  : { href }

  return (
    <div className='slide-image-wrapper'>
      <ImageLoader
        src={src}
        image={() => <img src={src} className={className}/>}
        loading={()=><div className='loading'><i className='fa fa-spin fa-spinner fa-2x'/></div> }
        error={()=><div className='loading'><i className='fa fa-spin fa-spinner fa-2x'/></div> }
      />

      <Attribution tags={imageTags} />
    </div>
  );
}

export default Hero;
