import React, { Component } from 'react';
import { Group } from 'react-filters';
import { differenceWith, filter, includes } from 'lodash';

class FilterBar extends Component {
  state = {
    options: [],
    selectedOptions: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // Since images load async this runs multiple times after mounting
    if(!nextProps.tagValues.length) { return null };

    const optionsFor = (tags) => {
      return tags.map((tag, index) => {
        return {
          id: index,
          label: tag,
          value: false,
          disabled: false,
          countElem,
          count: 0
        }
      });
    };

    // dummy element to satisfy PropTypes of Group
    const countElem = (option) => {
      return <span className="toggle-count"></span>;
    }

    const AllOption = {
      id: -1,
      label: "All",
      value: true,
      disabled: false,
      countElem,
      count: 0
    };

    // initial render with props
    let options = [AllOption, ...optionsFor(nextProps.tagValues)];

    // update with prevState, disabling options not applicable to result set
    if(prevState.options.length) {
      options = prevState.options.map((prevStateOption) => {
        // check relevent filter options from props
        // All
        if(prevStateOption.id === -1 && prevState.selectedOptions.length) {
          return {
            ...prevStateOption,
            countElem,
            count: (prevStateOption.count + 1), //arbitary counter to force re-render https://github.com/ritz078/react-filters/issues/32
            disabled: false,
            className: 'inactive'
          }
        }

        // option applies to next result set
        if(includes(nextProps.tagValues, prevStateOption.label)) {
          return {
            ...prevStateOption,
            countElem,
            count: (prevStateOption.count + 1), //arbitary counter to force re-render https://github.com/ritz078/react-filters/issues/32
            disabled: !prevStateOption.disabled,
            className: 'active'
          }
        }

        // option does not apply to next result set
        return {
          ...prevStateOption,
          countElem,
          count: (prevStateOption.count + 1), //arbitary counter to force re-render https://github.com/ritz078/react-filters/issues/32
          disabled: !prevStateOption.disabled,
          className: 'disabled'
        }
      });
    };

    const derivedState = {
      options
    }

    return derivedState;
  }

  resetValues(tagOptions) {
    return tagOptions.map((tagOption) => {
      if(tagOption.id === -1) {
        return {
          ...tagOption,
          value: true,
          count: (tagOption.count + 1),
          className: 'active',
        }
      }
      return { ...tagOption, value: false, disabled: false }
    })
  };

  handleValueChange({ oldValue, value, selectedIds }) {
    let selectedOptions = filter(value, (val) => includes(selectedIds, val.id) && val.id !== -1);
    let query = selectedOptions.map(selectedOption => selectedOption.label);

    // All
    if(!includes(selectedIds, -1)) {
      query = '';
      selectedOptions = [];
      value = this.resetValues(value)
    };

    this.setState({
      options: value,
      selectedOptions
    });

    this.props.onValueChange(query);
  };


  render() {
    return (
      <Group
        name='group-tag'
        type={'checkbox'}
        value={this.state.options}
        mode={'tag'}
        onChange={(checkGroup)=>this.handleValueChange(checkGroup)}
      />
    );
  }
}

export default FilterBar;
