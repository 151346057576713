import React, { Component, Fragment } from 'react';


class PreviewImage extends Component {
  constructor(props) {
    super(props);
    this.dz = this.props.dz;
    this.file = this.props.file;
    this.setThumb = this.setThumb.bind(this);
    this.state = {
      thumbUrl: '',
    }
  }

  componentDidMount() {
    this.createThumb(this.file);
  }

  setThumb(thumbUrl, canvas) {
    this.setState({ thumbUrl });
  }

  createThumb(file) {
    return this.dz.createThumbnail(this.file, this.dz.options.thumbnailWidth, this.dz.options.thumbnailHeight, this.dz.options.thumbnailMethod, true, this.setThumb)
  };

  formattedSize(size) {
    return { __html: this.dz.filesize(this.file.size) };
  }

  handleCoverChange(event, file) {
    file.cover = event.target.value;
  }

  handleAttributionChange(event, file) {
    file.attribution = event.target.value;
  }

  render() {
    return (
      <Fragment>
        <div className="dz-preview dz-file-preview mine" data-uuid={this.file.upload.uuid}>
          <div className='dz-file-info'>
            <div className="dz-image">
              <img src={this.state.thumbUrl} alt={this.file.name} />
              <div className="dz-progress" data-uuid={this.file.upload.uuid}>
                <span className="dz-upload" data-dz-uploadprogress="true"></span>
                <span className="dz-status">Queued</span>
              </div>
            </div>
            <div className="dz-details">
              <div className="dz-filename">
                <span data-dz-name>{this.file.name}</span>
              </div>
              <div className="dz-size" dangerouslySetInnerHTML={this.formattedSize()} />
          </div>
          </div>
          <div className='dz-actions'>
            <div>
              <label htmlFor='attribution'>Attribution:</label>
              <input type='text'
                className='attribution'
                onBlur={(event)=>this.handleAttributionChange(event, this.file)}
              />
            </div>
            <div>
              <label htmlFor='cover-radio'>Cover image</label>
              <input type='radio'
                className='cover-radio'
                onChange={(event) => this.handleCoverChange(event, this.file)}
              />
            </div>
            <button type='submit'
              className='dz-remove'
              onClick={()=>this.props.onFileRemove(this.file)}>
              <i className='fa fa-trash'/>
            </button>
          </div>

          <div className="dz-success-mark"></div>
          <div className="dz-error-mark"></div>
          <div className="dz-error-message"><span data-dz-errormessage></span></div>
        </div>
      </Fragment>
    )
  }
};

export default PreviewImage;
