import React from 'react';

import { parameterize } from '../../utils/utilities';

const LinkTag = ({tag, delim}) => {

  const urlTag = JSON.stringify(tag.slice(0,1));

  return (
    <a href={`/galleries/gallery?tags=${urlTag}`} className='tag'>
      <span>{`${tag[0]}${delim}`}</span>
    </a>
  )
};

export default LinkTag;
