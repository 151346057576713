import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import Lightbox from 'react-images';

import Slide from './components/Slide';
import Hero from './components/Hero';
import Description from './components/Description';
import LinkTag from '../common/components/LinkTag';

import * as Api from '../utils/api';
import ReactPhotoGalleryAdapter from '../common/adapters/v1/photoGallery';
import { randomize, sanitizeQuery, shuffleArray } from '../utils/utilities';
import { sortBy } from 'lodash';

class MediaSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      images: [],
      currentImage: 0,
    }

    this.containerClass = 'slider-container';

    // https://github.com/kenwheeler/slick#settings
    this.defaultSettings = {
      className: 'slider ',
      infinite: true,
      mobileFirst: true,
      centerMode: false,
      slidesToShow: 1,
      cssEase: 'ease',
      slidesToScroll: 1,
      featured: false,
    };
    this.verticalSettings = {
      vertical: true,
      verticalSwiping: true,
      centerMode: false,
      infinite: false,
      variableWidth: false,
      adaptiveHeight: true,
      arrows: false,
      draggable: false,
      touchMove: false,
      swipe: false,
    };
    this.important = {
      speed: 300,
      swipeToSlide: true,
      edgeFriction: 0.15,
      touchThreshold: 100,
      focusOnSelect: false,
      variableWidth: true,
      adaptiveHeight: false,
      fade: false,
      rows: 1,
    };

    this.settings = {
      ...this.defaultSettings, //compatible defaults
      ...props.settings,       //from the outside
      ...this.important        //non-outside configurable
      // end
    };


    if(this.settings.hasOwnProperty('vertical')) {
      this.containerClass += ' vertical'
      this.settings = {
        ...this.defaultSettings,
        ...this.verticalSettings,
        ...props.settings,
        ...this.important
        // end
      }
    }

    this.lightboxSettings = {
      backdropClosesModal: true,
      enableKeyboardInput: true,
      closeButtonTitle: "Close to see more from this gallery",
    }
  };

  onSuccess(response){
    if (!response || !response.body.meta.total > 0) { return []; }
    let data = ReactPhotoGalleryAdapter(response)

    if(this.settings.hasOwnProperty('randomOrder')) {
      shuffleArray(data)
    };
    this.setState({
      images: data
    });
  };

  requestImages(query = '') {
    const sanitizedQuery = {
      ...query,
      tags: sanitizeQuery(query.tags)
    };
    Api.get({
      query: sanitizedQuery,
      success: (response) => this.onSuccess(response),
      failure: (error) => this.onFailure(error)
    })
  };

  componentDidMount() {
    this.requestImages(this.props.query)
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  render() {
    // TODO add conditional loader
    if(this.state.images.length === 0) { return null }
    const classNames = this.settings.featured === true ? 'featured' : null;
    let images = this.state.images;

    let selectedImage;
    const uid = this.props.uid;

    // sort selected uid to the top of the array
    if(uid) {
      images = sortBy(images, (image) => {
        return image.id === uid ? 0 : 1
      })
    }

    if(this.settings.single) {
      this.containerClass += ' single'
      const image = selectedImage || randomize(images)

      return (
        <div className={this.containerClass} title={`${this.props.query.tags}`} data-toggle='tooltip'>
          <Hero
            src={image.src}
            image={image}
            reservedTags={['coverimage', 'featured', 'hero']}
          />
        </div>
      )
    };
    return (
      <div className={this.containerClass} title={`${this.props.query.tags}`} data-toggle='tooltip'>
        <Slider {...this.settings}>
          {
            images.map((image, index) => {
              const renderDescription = index === 0 && this.settings.hasOwnProperty('vertical')
              const onClick = this.settings.hasOwnProperty('lightbox') ? (event, obj)=>this.openLightbox(event, obj) : null
              let src;
              if (this.settings.vertical) {
                src = image.src
              }
              else if (this.settings.featured) {
                src = image.large
              }
              else {
                src = image.medium
              }

              return (
                <Fragment key={image.id}>
                  <Slide
                    src={src}
                    index={index}
                    image={image}
                    className={classNames}
                    tagComponent={LinkTag}
                    onClick={onClick}
                    reservedTags={['coverimage', 'featured', 'hero', 'artist:']}
                  />

                  {
                    renderDescription && <Description key={index} tags={this.props.query.tags.split(',')}/>
                  }
                </Fragment>
              )

            }, this.props)
          }
        </Slider>
        <Lightbox
          {...this.lightboxSettings}
          images={images}
          onClose={()=>this.closeLightbox()}
          onClickPrev={()=>this.gotoPrevious()}
          onClickNext={()=>this.gotoNext()}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </div>
    );
  }

}

export default MediaSlider;
