import React, { PureComponent } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { uniq, flattenDeep, sortBy, difference, pullAll, intersection } from 'lodash';
import Gallery from '../../../common/components/Gallery';
import ImageUploader from '../components/ImageUploader';
import TagSelector from '../components/TagSelector';
import SelectableImage from '../components/SelectableImage';

// TODO add root path to webpacker resolvedPaths to avoid silly paths
import * as Api from '../../../utils/api';
import ReactPhotoGalleryAdapter from '../../../common/adapters/v1/photoGallery';
class App extends PureComponent {
  state = {
    uploaderIsOpen: false,
    requiresSync: false,
    selectAll: false,
    existingImageTags: [],

    images: [],
    searchQuery: [],
    tags: [],
    reservedTags: ['coverimage', 'featured', 'hero'],
    selectedImages: [],
    filteredImages: null,
    filteredImageTags: [],
    filteredReservedTags: null,
  }

  clearSyncState() {
    this.setState({ requiresSync: false })
  }

  handleQueryChange(query) {
    this.setState({ query })
  }

  openImageUploader() {
    this.setState({ uploaderIsOpen: true });
  };

  onCloseImageUploader() {
    this.setState({ uploaderIsOpen: false });
    this.requestImages();
  };

  handleSelectImage(event, obj) {
    const images = this.state.filteredImages || this.state.images
    let image = images[obj.index];
    image.selected = !image.selected;

    this.setState({
      images,
      selectedImages: this.selectedImages()
    });
  }

  // API
  onSuccess(response) {
    const newImages = ReactPhotoGalleryAdapter(response)
    const existingImageTags = newImages.map((image) => {
      return image.object.attributes.tags
    })
    let sortedUniqTags = sortBy(uniq(flattenDeep(existingImageTags)))
    sortedUniqTags = pullAll(sortedUniqTags, this.state.reservedTags)

    this.setState({
      images: newImages,
      filteredImages: null,
      selectedImages: [],
      tags: [],
      existingImageTags: sortedUniqTags,
      filteredImageTags: sortedUniqTags,
      reservedTags: this.state.reservedTags,
    });

    this.clearSyncState();
  }
  onFailure(error, response){
    console.log(response, error);
  }

  requestImages(query = '') {
    Api.get({
      query: query,
      success: (response) => this.onSuccess(response),
      failure: (error) => this.onFailure(error)
    })
  };

  componentDidMount() {
    this.requestImages()
  }

  handleDeleteImage(event) {
    event.preventDefault();

    if(!this.state.selectAll) {
      this.selectedImages().map((selectedImage) => {
        Api.del({
          path: `/images/${selectedImage.id}`,
          success: (response) => this.requestImages(),
          failure: (error) => this.onFailure(error),
        });
      });
    }
  }

  // Gallery handlers
  toggleSelect() {
    let images = this.state.filteredImages || this.state.images
    images = images.map((image, index) => { return { ...image, selected: !this.state.selectAll } });
    this.setState({ images, selectedImages: images, filteredImages: images, selectAll: !this.state.selectAll });
  }

  handleSearchSubmit(tags) {
    const tagValues = tags.map(tag => tag.value);

    const filteredImages = this.state.images.filter((image) => {
      return difference(tagValues, image.object.attributes.tags).length === 0;
    }, tagValues)

    let filteredImageTags = filteredImages.map(image => image.object.attributes.tags)
    filteredImageTags = sortBy(uniq(flattenDeep(filteredImageTags)))

    const filteredReservedTags = intersection(this.state.reservedTags, filteredImageTags)
    pullAll(filteredImageTags, filteredReservedTags)

    this.setState({
      searchQuery: tags,
      filteredImages,
      filteredImageTags,
      filteredReservedTags
    });

    // reset
    if(!tags.length) {
      this.setState({
        filteredReservedTags: this.state.reservedTags
      })
      return this.requestImages();
    }
  }

  handleTagSubmit(tags) {
    const tagValues = tags.map(tag => tag.value);
    this.setState({ tags: tagValues });
  }

  handleUpdateTag(event) {
    event.preventDefault();

    this.selectedImages().map((selectedImage, index, self) => {
      let tags = this.state.tags;

      // appending tags to multiple images
      if(self.length > 1) {
        tags = [
          ...selectedImage.object.attributes.tags,
          ...tags
        ]
      }

      Api.patch({
        path: `/images/${selectedImage.id}`,
        data: { tags: JSON.stringify(tags) },
        success: (response) => this.requestImages(),
        failure: (error) => this.onFailure(error),
      });
      this.setState({ searchQuery: [] });
    });
  };

  selectedImages(){
    return this.state.images.filter((image) => {
      return image.selected === true;
    });
  }
  handleTagSubmit(tags) {
    const tagValues = tags.map(tag => tag.value);
    this.setState({ tags: tagValues });
  }


  render() {
    const pendingTags = this.state.tags.length > 0;
    const addTagState = (this.selectedImages().length === 0 || !pendingTags) ? { 'disabled': 'disabled' } : null;
    const deleteState = (this.selectedImages().length === 0 || this.state.selectAll) ? { 'disabled': 'disabled' } : null;
    const copyUrlState = (this.selectedImages().length === 0) ? { 'disabled': 'disabled' } : null;
    let selectedImageTags = [];
    let tagLabel = "Add tag(s)"

    // allow atomic updates to existing tags (update, delete)
    if(this.state.selectedImages.length === 1) {
      selectedImageTags = this.state.selectedImages[0].object.attributes.tags;
      tagLabel = "Update Tags";
      // allow appending tags to multiple images
    } else if (this.state.tags.length) {
      selectedImageTags = this.state.tags;
    }

    const images = this.state.filteredImages || this.state.images;
    const reservedTags = this.state.filteredReservedTags || this.state.reservedTags;

    return (
      <div className='media-manager'>
        <div className='toolbar'>
          <div className='search-bar'>
            <TagSelector
              onValuesChange={(tags)=>this.handleSearchSubmit(tags)}
              placeholder="Search by tags (comma separated)"
              delimiters={[188, 9]}
              selectedImageTags={this.state.searchQuery}
              existingImageTags={this.state.filteredImageTags}
              reservedTags={reservedTags}
            />
          </div>
          <button className='upload-button' onClick={() => this.openImageUploader()}>
            <i className='fa fa-2x fa-cloud-upload'/>
            <span>Upload Images</span>
          </button>
        </div>
        <div className='selectable-actions'>
          <TagSelector
            onValuesChange={(tags)=>this.handleTagSubmit(tags)}
            placeholder="Enter tag(s)"
            delimiters={[188, 9]}
            selectedImageTags={selectedImageTags}
            existingImageTags={this.state.existingImageTags}
            reservedTags={this.state.reservedTags}
          />
          <div className='action-buttons'>
            <button {...addTagState} className='tag-selected-button'
                    onClick={(event) => this.handleUpdateTag(event)}>
              <span><i className='fa fa-tag'/>{tagLabel}</span>
            </button>
            <button {...deleteState} className='delete-selected-button'
                    onClick={(event) => this.handleDeleteImage(event)}>
              <span><i className='fa fa-trash'></i>Delete selected</span>
            </button>
            <CopyToClipboard {...copyUrlState} text={this.state.selectedImages.map((image) => image.src)}>
              <button><i className='fa fa-clipboard'></i>Copy Image URL to clipboard</button>
            </CopyToClipboard>
            <button
              onClick={() => this.toggleSelect()}>
              <i className="fa fa-check-circle"/>
              { this.state.selectAll ? 'Unselect all' : 'Select all' }
            </button>
          </div>
        </div>
        <div className='main'>
          <Gallery
            images={images}
            query={this.state.query}
            onSelectImage={(event, obj)=>this.handleSelectImage(event, obj)}
            imageComponent={SelectableImage}
          />
          <ImageUploader
            isOpen={this.state.uploaderIsOpen}
            onCloseImageUploader={() => this.onCloseImageUploader()}
            existingImageTags={this.state.existingImageTags}
            reservedTags={this.state.reservedTags}
          />
        </div>
      </div>
    );
  }
}

export default App;
