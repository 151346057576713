import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sanitizeQuery } from '../../utils/utilities';

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {
      query: '',
      tags: [],
    }
  }

  onInputChange(query) {
    const sanitizedQuery = sanitizeQuery(query);
    this.setState({query: sanitizedQuery});
    this.props.onQueryChange({tags: sanitizedQuery});
  }

  render() {
    return (
      <div className="search">
        <input placeholder="Search by tag" onChange={event => this.onInputChange(event.target.value)} />
      </div>
    );
  }
}

SearchBar.propTypes = {
  onQueryChange: PropTypes.func.isRequired,
};

export default SearchBar;
