export const parameterize = (str) => {
  return str.trim()
            .replace(/[^a-zA-Z0-9-\s]/g, '')
            .replace(/[^a-zA-Z0-9-]/g, '-')
            .toLowerCase();
};

export const randomize = (collection) => {
  return collection[Math.floor(Math.random() * collection.length)];
};

export const sanitizeQuery = (dirty) => {
  const sanitizedQuery = dirty.replace(/\+/g, ' ,');
  return JSON.stringify(sanitizedQuery.split(','));
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
