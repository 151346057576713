import React from 'react';
import TagListWithCounts from '../../common/components/TagListWithCounts';
import LinkTag from '../../common/components/LinkTag';
import { sanitizeQuery } from '../../utils/utilities';

const cont = {
  backgroundColor: '#eee',
  cursor: 'pointer',
  overflow: 'hidden',
  float: 'left',
  position: 'relative'
};

const ClickableGallery = ({ index, onClick, photo, margin }) => {
  const reservedTags=['coverimage', 'featured', 'hero'];
    let href;

    let imageTags = photo.object.attributes.tags;
    imageTags = imageTags.filter((tag) => {
      return reservedTags.indexOf(tag) === -1
    });

    if(imageTags.length) {
      const sanitizedQuery = sanitizeQuery(imageTags.join());
      href = `/galleries/gallery?tags=${sanitizedQuery}&uid=${photo.id}`
    };

  const anchor = { href };
  return (
    <div className='gallery-img-wrapper' style={{ margin, width: photo.width, ...cont }}>
      <a {...anchor} >
        <img src={photo.medium} height={photo.height} width={photo.width} />
      </a>
      <div className='metadata'>
        <TagListWithCounts tagsWithCount={photo.tags_with_count} tagComponent={LinkTag} reservedTags={reservedTags} />
      </div>
    </div>
  )
};

export default ClickableGallery;
