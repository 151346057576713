import React, { Component } from 'react';
import { MultiSelect} from 'react-selectize';
import { unionWith, differenceWith, reject } from 'lodash';


const noResultsHandler = (values, search) => {
  return (
    <div className = "no-results-found">
      {
        (() => {
          if (search.trim().length == 0)
            return "Type a few characters to create a tag";
          else if (values.map((item) => { return item.label; }).indexOf(search.trim()) != -1)
            return "Tag already exists";
        })()
      }
    </div>
  )
};

const createFromSearchHandler = (options, values, search) => {
  const labels = values.map((value) => {
    return value.label;
  })

  if (search.trim().length == 0 || labels.indexOf(search.trim()) != -1)
    return null;
  return { label: search.trim(), value: search.trim() };
};

const renderOptionHandler = (tag) => {
  const optionStyle = tag.label === '(Current Date)' ? {
    backgroundColor: "\#f8f8f8",
    fontStyle: "oblique",
  } : {};

  return <div className="simple-option" style={optionStyle}>
    <span>{tag.label}</span>
  </div>
}

const createOption = (valuesArray, groupId) => {
  return valuesArray.map((value, idx) => {
    return { label: value, value: value, key: idx, groupId: groupId}
  })
}

class TagSelector extends Component {
  state = {
    tags: [],
    options: [],
  }

  backspaceHandler(tag) {
    return ''
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // adding tag
    let uniqueTags = unionWith(
      prevState.tags,
      createOption(nextProps.selectedImageTags, {}),
      _.isEqual
    );

    // removing tag
    let diff = differenceWith(uniqueTags, prevState.tags, _.isEqual)
    if(diff.length && prevState.tags.length) {
      uniqueTags = [...prevState.tags]
    }

    // unselect image
    if(!nextProps.selectedImageTags.length) {
      uniqueTags = []
    }

    // Select options
    const currentDate = { label: '(Current Date)', value: new Date().toJSON().slice(0,10), groupId: 'system' };

    const existingImageTags = createOption(nextProps.existingImageTags, 'custom');
    const reservedTags = createOption(nextProps.reservedTags, 'system');
    const options = [
      currentDate,
      ...reservedTags,
      ...existingImageTags,
    ]

    const uniqueDerivedState = {
      tags: uniqueTags,
      options,
    };

    return uniqueDerivedState
  }

  handleValuesChange(tags) {
    this.setState({ tags });
    this.props.onValuesChange(tags);
  }

  renderValue(renderedTag) {
    return (
      <div className="value-wrapper">
        <div className="simple-value">
          <i className='fa fa-tag'></i>
          <span>{renderedTag.label.toLowerCase()}</span>
          <span onClick = {() => {
            const tags = reject(this.state.tags, tag => tag.value == renderedTag.value);
            this.setState({ tags });
            return this.props.onValuesChange(tags);
          }}>x</span>
        </div>
      </div>
    )
  };


  render() {

    const options = this.state.options
    return(
      <div className='tag-selector'>
        <MultiSelect
          groups={[{ groupId: 'system', title: 'System Tags' }, { groupId: 'custom', title: 'Custom Tags' }]}
          groupsAsColumns = {true}
          renderOption={(tag)=>renderOptionHandler(tag)}
          options={options}
          firstOptionIndexToHighlight={(optionIndex, options, value, search) => {
            return (optionIndex == 0 && search.length == 0) ? -1 : optionIndex
          }}
          values={this.state.tags}
          placeholder = {this.props.placeholder}
          delimiters = {this.props.delimiters}
          restoreOnBackspace = {(item) => this.backspaceHandler(item)}
          renderNoResultsFound = {(values, search) => noResultsHandler(values, search)}
          createFromSearch = {(options, values, search) => createFromSearchHandler(options, values, search)}
          onValuesChange = {(tags) => this.handleValuesChange(tags)}
          renderValue = {(inputVal) => this.renderValue(inputVal)}
        />
      </div>
    )
  }
}


export default TagSelector;
